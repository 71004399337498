import {
    Modal,
    ModalOverlay,
    ModalContent,
    Button,
    VStack,
    ModalCloseButton,
    HStack,
    FormControl,
    Textarea,
    Text,
    Select,
    Card,
    Box
} from '@chakra-ui/react';
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import { useSupportTicketChat } from "../providers/SupportTicketChatProvider";
import { useEffect } from 'react';
import { useSupportTickets } from '../providers/SupportTicketProvider';
import { useAuth } from '../providers/AuthProvider';

export default function TicketReply({ isOpen, onClose, ticketDetails }) {
    const { addTicketChat, changeStatusOfTicket, getTicketChats, ticketChats } = useSupportTicketChat();
        const { getSupportTicket, getUnresolvedTicket, getResolvedTicket } = useSupportTickets();
        const { user } = useAuth();

    const formik = useFormik({
        initialValues: {
            reply_text: "",
            status: ticketDetails.status
        },
        onSubmit: async (values) => {
            try {
                if(values.reply_text.trim() === '') {
                   return toast.error("Reply text cannot be empty!");
                }

                await addTicketChat(ticketDetails._id, values.reply_text, user._id, ticketDetails.userId._id);

                onClose();
            } catch (error) {
                toast.error("An error occurred while processing your request.");
                console.error(error);
            }
        }
    });

    const handleStatusChange = async (status) => {
        await changeStatusOfTicket(ticketDetails._id, status);
        await getSupportTicket();
        await getUnresolvedTicket();
        await getResolvedTicket();

        formik.setFieldValue("status", status); 
    }

    useEffect(() => {
        getTicketChats(ticketDetails._id);
        console.log(ticketChats);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const statusColors = {
        'Closed': 'green.500',
        'Open': 'blue.500',
        'Inprocess': 'yellow.500'
    };

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} isCentered onOpen size={{ base: 'lg', md: '2xl' }}>
                <ModalOverlay />
                <ModalContent mx={{ base: 4, md: 0 }}>
                    <ModalCloseButton />
                    <VStack mx={5} w={'auto'} alignItems={'start'} mt={4}>
                        <HStack spacing={4} mb={1} w="100%">
                            <Text fontSize={'xs'} fontWeight={'500'}>
                                Status:
                            </Text>
                            <Select
                                id="status"
                                name="status"
                                value={formik.values.status}
                                onChange={formik.handleChange}
                                size="sm"
                                fontWeight={'500'}
                                width="auto"
                                color={statusColors[formik.values.status] || 'gray.500'}
                            >
                                <option value="Open">Open</option>
                                <option value="Inprocess">In Process</option>
                                <option value="Closed">Closed</option>
                            </Select>
                            <Button
                                type="button"
                                bg="green.500"
                                borderRadius={'6'}
                                fontSize={'xs'}
                                color={'white'}
                                onClick={() => handleStatusChange(formik.values.status)}
                            >
                                Update
                            </Button>
                        </HStack>
                        <Card p={4} w={'100%'} bg='primary.50'>
                            <Text fontSize={'sm'} color='primary.800' fontWeight={'600'}>{ticketDetails.title}</Text>
                            <Text fontSize={'xs'} fontWeight={'400'}>{ticketDetails.description}</Text>
                        </Card>
                    </VStack>
                    <Box
                        maxH="300px"
                        overflowY="auto"
                        p={4}
                        mx={5}
                        border="px"
                        borderRadius="10"
                        borderColor="gray.100"
                    >
                        <VStack spacing={2} align="stretch">
                            {ticketChats.map((chat, index) => {
                                const fromUserRole = chat.from_user.roles;
                                const isUser = fromUserRole === 'User';
                                return (
                                    <Box
                                        key={index}
                                        alignSelf={isUser ? 'flex-start' : 'flex-end'}
                                        bg={isUser ? 'gray.100' : 'blue.500'}
                                        color={isUser ? 'black' : 'white'}
                                        borderRadius="md"
                                        p={2}
                                        maxW="80%"
                                    >
                                        <Text fontSize="xs" fontWeight="400">
                                            {chat.message}
                                        </Text>
                                    </Box>
                                );
                            })}
                        </VStack>
                    </Box>
                    <form onSubmit={formik.handleSubmit}>
                        <HStack w={'auto'} alignItems={'center'} mx={5} mt={6} spacing={4} mb={4}>
                            <FormControl flex="3">
                                <Textarea
                                    id="reply_text"
                                    name="reply_text"
                                    type="text"
                                    variant="filled"
                                    required={true}
                                    fontSize="sm"
                                    bg="none"
                                    borderColor="gray.100"
                                    _focus={{
                                        borderColor: "primary.700",
                                    }}
                                    borderRadius="10"
                                    value={formik.values.reply_text}
                                    onChange={formik.handleChange}
                                />
                            </FormControl>
                            <Button
                                type="submit"
                                bg="primary.700"
                                py='6'
                                borderRadius={'6'}
                                fontSize={'xs'}
                                color={'white'}
                            >
                                Send Reply
                            </Button>
                        </HStack>
                    </form>
                </ModalContent>
            </Modal>
        </>
    );
}