import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    Button,
    VStack,
    ModalHeader,
    ModalCloseButton,
    Input,
    InputGroup,
    InputLeftElement,
    FormControl,
    Text,
    HStack,
    Checkbox,
    Image
} from '@chakra-ui/react';
import { IoSearch } from "react-icons/io5";
import { useNotificationGroup } from '../../providers/NotificationGroupProvider';
import { useNotification } from '../../providers/NotificationProvider';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export default function SendToSpecificUsers({ isOpen, onClose, title, description, image }) {
    const { activeUsers, searchUser } = useNotificationGroup();
    const { sendToSpecificUsers } = useNotification();

    const [selectedUsers, setSelectedUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    const handleSelectUser = (userId) => {
        setSelectedUsers((prev) => {
            const updated = prev.includes(userId)
                ? prev.filter((id) => id !== userId) // Remove if already selected
                : [...prev, userId]; // Add new ID
            console.log("Updated Selected Group IDs:", JSON.stringify(updated)); // Log with double quotes
            return updated;
        });
    };

    const handleSubmit = async () => {
        try {
            console.log("Selected Users:", selectedUsers);

            if (selectedUsers.length === 0) {
                return toast.error("Please select at least one user.");
            }

            await sendToSpecificUsers(title, description, image, selectedUsers);
            setSelectedUsers([]);
            onClose();
        } catch (error) {
            console.error("Failed to send notifications:", error);
            toast.error("Failed to send notification. Please try again.");
        }
    };

    useEffect(() => {
        searchUser(searchQuery).then(() => {
        });
    }, [searchQuery]);

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} isCentered onOpen size={'md'} >
                <ModalOverlay />
                <ModalContent mx={{ base: 4, md: 0 }}>

                    <ModalHeader fontSize={'md'} fontWeight={'500'} w='100%'>
                        <FormControl w={{ base: '83%', md: '85%' }}>
                            <InputGroup>
                                <InputLeftElement
                                    pointerEvents="none"
                                    children={<IoSearch size={'18px'} />}
                                />
                                <Input
                                    id="searchUser"
                                    name="searchUser"
                                    type="text"
                                    variant="filled"
                                    required={true}
                                    placeholder="Search User"
                                    fontSize={{ base: 'xs', md: 'sm' }}
                                    py={4}
                                    px={10}
                                    _focus={{
                                        borderColor: "primary.800",
                                    }}
                                    borderRadius="10"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </InputGroup>
                        </FormControl>
                    </ModalHeader>

                    <ModalCloseButton />

                    <VStack spacing={6} maxH="40vh" overflowY="auto">
                        {activeUsers.map((u) => (
                            <HStack key={u._id} w={'100%'} px={8}>
                                <Checkbox
                                    size='lg'
                                    colorScheme='blue'
                                    mr={2}
                                    isChecked={selectedUsers.includes(u._id)}
                                    onChange={() => handleSelectUser(u._id)}
                                />
                                {u.profile_picture ? (
                                    <Image
                                        src={u.profile_picture}
                                        w={'33px'}
                                        h={'33px'}
                                        alt='user'
                                        border={'2px'}
                                        borderColor='primary.800'
                                        borderRadius="50%"
                                        boxShadow={'lg'}
                                    />
                                ) : (
                                    <Image src='../user_demo.png' width={'32px'} alt='user' />
                                )}
                                <VStack ml={1} spacing={0} alignItems={'start'}>
                                    <Text fontSize={'sm'}><Text fontSize={'sm'}> {u.first_name ?
                                        <Text pl={{ base: 2, md: 0 }}>
                                            {u.first_name} {u.last_name}
                                        </Text>
                                        : <Text>NA</Text>}</Text></Text>
                                    <Text fontSize={'xs'} color={'grey'}>{u.email}</Text>
                                </VStack>
                            </HStack>
                        ))}
                    </VStack>

                    <ModalFooter w={'100%'} px={16}>
                        <Button
                            mb={2}
                            type="submit"
                            bg="primary.800"
                            color={'white'}
                            py='4'
                            width="full"
                            borderRadius={'50'}
                            fontSize={'sm'}
                            onClick={handleSubmit}
                        >
                            Send
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}



