import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    Button,
    VStack,
    ModalHeader,
    ModalCloseButton,
    Input,
    SimpleGrid,
    InputGroup,
    InputLeftElement,
    FormControl,
} from '@chakra-ui/react';
import { useFormik } from "formik";
import { FaRegUserCircle } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { useAdminMembers } from '../providers/AdminProvider';
import { IoEyeOutline } from "react-icons/io5";

export default function CreateNewAdmin({ isOpen, onClose, onOpen }) {
    const { addAdminMember } = useAdminMembers();

    const formik = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            role: "Role Type: Admin",
        },
        onSubmit: async (values) => {
            try {
                await addAdminMember(values);
                values.first_name = "";
                values.last_name = "";
                values.email = "";
                values.password = "";
            } catch (error) {
                console.error(error);
            }finally {
                onClose();
            }
            console.log(values);
        }
    });

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} isCentered onOpen size={{ base: 'sm', md: '2xl' }}>
                <ModalOverlay />
                <ModalContent mx={{ base: 4, md: 0 }}>
                    <ModalHeader fontSize={'md'} color={'white'} bg='primary.700' w='100%'>
                        Create New Admin
                    </ModalHeader>
                    <ModalCloseButton color={'white'} />

                    <form onSubmit={formik.handleSubmit}>
                        <VStack w={'auto'} alignItems={'start'} mx={4} mt={6}>
                            <SimpleGrid w={'100%'} columns={{ base: 1, lg: 2 }} spacing={4}>
                                <FormControl>
                                    <InputGroup>
                                        <InputLeftElement
                                            pointerEvents="none"
                                            children={<FaRegUserCircle color="#3654EA" size={'18px'} />}
                                            mt={2}
                                        />
                                        <Input
                                            id="first_name"
                                            name="first_name"
                                            type="text"
                                            variant="filled"
                                            required={true}
                                            placeholder="First Name"
                                            fontSize="lg"
                                            py={6}
                                            px={10}
                                            bg="none"
                                            borderColor="gray.100"
                                            _focus={{
                                                borderColor: "primary.800",
                                            }}
                                            borderRadius="10"
                                            value={formik.values.first_name}
                                            onChange={formik.handleChange}
                                        />
                                    </InputGroup>
                                </FormControl>
                                <FormControl>
                                    <InputGroup>
                                        <InputLeftElement
                                            pointerEvents="none"
                                            children={<FaRegUserCircle color="#3654EA" size={'18px'} />}
                                            mt={2}
                                        />
                                        <Input
                                            id="last_name"
                                            name="last_name"
                                            type="text"
                                            variant="filled"
                                            required={true}
                                            placeholder="Last Name"
                                            fontSize="md"
                                            py={6}
                                            px={10}
                                            bg="none"
                                            borderColor="gray.100"
                                            _focus={{
                                                borderColor: "primary.800",
                                            }}
                                            borderRadius="10"
                                            value={formik.values.last_name}
                                            onChange={formik.handleChange}
                                        />
                                    </InputGroup>
                                </FormControl>
                                <FormControl>
                                    <InputGroup>
                                        <InputLeftElement
                                            pointerEvents="none"
                                            children={<IoIosMail color="#3654EA" size={'18px'} />}
                                            mt={2}
                                        />
                                        <Input
                                            id="email"
                                            name="email"
                                            type="email"
                                            variant="filled"
                                            required={true}
                                            placeholder="Email ID"
                                            fontSize="md"
                                            py={6}
                                            px={10}
                                            bg="none"
                                            borderColor="gray.100"
                                            _focus={{
                                                borderColor: "primary.800",
                                            }}
                                            borderRadius="10"
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                        />
                                    </InputGroup>
                                </FormControl>

                                <FormControl>
                                    <InputGroup>
                                        <InputLeftElement
                                            pointerEvents="none"
                                            children={<IoEyeOutline color="#3654EA" size={'18px'} />}
                                            mt={2}
                                        />
                                        <Input
                                            id="password"
                                            name="password"
                                            type="password"
                                            variant="filled"
                                            required={true}
                                            placeholder="Password"
                                            fontSize="md"
                                            py={6}
                                            px={10}
                                            bg="none"
                                            borderColor="gray.100"
                                            _focus={{
                                                borderColor: "primary.800",
                                            }}
                                            borderRadius="10"
                                            value={formik.values.password}
                                            onChange={formik.handleChange}
                                        />
                                    </InputGroup>
                                </FormControl>
                                <FormControl>
                                    <InputGroup>
                                        <InputLeftElement
                                            pointerEvents="none"
                                            children={<FaRegUserCircle color="#3654EA" size={'18px'} />}
                                            mt={2}
                                        />
                                        <Input
                                            id="role"
                                            name="role"
                                            type="text"
                                            variant="filled"
                                            required={true}
                                            // placeholder="Role Type: Admin"
                                            disabled={true}
                                            fontSize="md"
                                            py={6}
                                            px={10}
                                            bg="none"
                                            borderColor="gray.100"
                                            _focus={{
                                                borderColor: "primary.800",
                                            }}
                                            borderRadius="10"
                                            value={formik.values.role}
                                            onChange={formik.handleChange}
                                        />
                                    </InputGroup>
                                </FormControl>
                            </SimpleGrid>

                            <ModalFooter w={'100%'} px={16}>
                                <Button
                                    my={4}
                                    type="submit"
                                    bg="primary.700"
                                    color={'white'}
                                    py='6'
                                    width="full"
                                    borderRadius={'50'}
                                    fontSize={'sm'}
                                >
                                    Create New User
                                </Button>
                            </ModalFooter>

                        </VStack>
                    </form>
                </ModalContent>
            </Modal>
        </>
    )
}


