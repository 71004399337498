
import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { url } from "../config";
import { useAuth } from "./AuthProvider";

const SupportTicketContext = createContext();

const SupportTicketProvider = ({ children }) => {
    const [tickets, setTickets] = useState([]);
    const [totalTicket, setTotalTicket] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const [resolvedTickets, setResolvedTickets] = useState([]);
    const [totalResolvedTicket, setTotalResolvedTicket] = useState(0);
    const [resolvedCurrentPage, setResolvedCurrentPage] = useState(0);
    const [totalResolvedPages, setResolvedTotalPages] = useState(0);

    const [unresolvedTickets, setUnresolvedTickets] = useState([]);
    const [totalUnresolvedTicket, setTotalUnresolvedTicket] = useState(0);
    const [unresolvedCurrentPage, setUnresolvedCurrentPage] = useState(0);
    const [totalUnresolvedPages, setUnresolvedTotalPages] = useState(0);

    const [loading, setLoading] = useState(true);

    const { token, logged } = useAuth();

    async function getSupportTicket(page) {
        console.log("Get Ticket");
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            var { data } = await axios.get(`${url}/admin/tickets?page=${page}`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
            });
            setTickets(data.data);
            setTotalTicket(data.total);
            setCurrentPage(data.page);
            setTotalPages(data.pages);
        } catch (error) {
            console.error("GET TICKETS ERROR", error);
            if (error.response?.status === 401) {
                console.error("NOT AUTHENTICATED");
                // logout();
            }
        } finally {
            setLoading(false);
        }
    }

    async function getResolvedTicket(page) {
        console.log("Get Resolved Ticket");
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            var { data } = await axios.get(`${url}/admin/tickets/resolved?page=${page}`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
            });
            setResolvedTickets(data.data);
            setTotalResolvedTicket(data.total);
            setResolvedCurrentPage(data.page);
            setResolvedTotalPages(data.pages);
        } catch (error) {
            console.error("GET RESOLVED TICKETS ERROR", error);
            if (error.response?.status === 401) {
                console.error("NOT AUTHENTICATED");
                // logout();
            }
        } finally {
            setLoading(false);
        }
    }

    async function getUnresolvedTicket(page) {
        console.log("Get Unresolved Ticket");
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            var { data } = await axios.get(`${url}/admin/tickets/unresolved?page=${page}`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
            });
            setUnresolvedTickets(data.data);
            setTotalUnresolvedTicket(data.total);
            setUnresolvedCurrentPage(data.page);
            setUnresolvedTotalPages(data.pages);
        } catch (error) {
            console.error("GET UNRESOLVED TICKETS ERROR", error);
            if (error.response?.status === 401) {
                console.error("NOT AUTHENTICATED");
                // logout();
            }
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getSupportTicket();
        getResolvedTicket();
        getUnresolvedTicket();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // Memoized value of the authentication context
    const contextValue = useMemo(
        () => ({
            loading,
            tickets,
            currentPage,
            totalTicket,
            totalPages,
            resolvedTickets,
            totalResolvedTicket,
            resolvedCurrentPage,
            totalResolvedPages,
            unresolvedTickets,
            totalUnresolvedTicket,
            unresolvedCurrentPage,
            totalUnresolvedPages,
            getSupportTicket,
            getResolvedTicket,
            getUnresolvedTicket
        }), [
        loading, tickets, currentPage, totalTicket, totalPages, resolvedTickets, totalResolvedTicket, resolvedCurrentPage, totalResolvedPages, unresolvedTickets, totalUnresolvedTicket, unresolvedCurrentPage, totalUnresolvedPages
    ]); // eslint-disable-line react-hooks/exhaustive-deps

    // Provide the authentication context to the children components
    return (
        <SupportTicketContext.Provider value={contextValue}>{children}</SupportTicketContext.Provider>
    );
};

export const useSupportTickets = () => {
    return useContext(SupportTicketContext);
};

export default SupportTicketProvider;
