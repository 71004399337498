import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { url } from "../config";
import { toast } from "react-toastify";
import { useAuth } from "./AuthProvider";

const ScheduledNotificationContext = createContext();

const ScheduledNotificationProvider = ({ children }) => {
    const [scheduledNotifications, setScheduledNotification] = useState([]);
    const [loading, setLoading] = useState(true);

    const { token, logged, logout } = useAuth();

    async function addScheduledNotification(title, description, date, time, image) {
        console.log(title, description, date, time, image)
        setLoading(true);
        if (!logged) {
            return;
        }
        // using multipart formdata to upload images
        var formData = new FormData();
        formData.append('image', image);
        // adding other fields 
        formData.append('title', title);
        formData.append('description', description);
        formData.append('date', date);
        formData.append('time', time);

        try {
            const res = await axios.post(
                `${url}/admin/scheduled-notifications`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`,
                    }
                }
            );
            await getScheduledNotifications();
            toast.success("Added !");
            return res.data;
        } catch (error) {
            console.error(error);
            if (error.response.status === 400) {
                toast.error(error.response.data.error);
            }
            if (error.response.status === 401) {
                toast.error("Session Expired");
                logout();
            }
            throw error;
        }
    }

    async function getScheduledNotifications() {
        console.log("Get Faqs");
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            var { data } = await axios.get(`${url}/admin/scheduled-notifications`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
            });
            setScheduledNotification(data);

        } catch (error) {
            console.error("GET FAQ ERROR");
            console.error(error);
            if (error.response.status === 401) {
                console.error("NOT AUTHENTICATED");
                // logout();
            }
            return "err";
        }
    }

    async function deleteScheduledNotification(id) {
        console.log(id)
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            await axios.delete(`${url}/admin/scheduled-notifications`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    data: {
                        "id": id,
                    },
                }

            );
            await getScheduledNotifications();
            toast.success("Deleted Successfully !");
        } catch (error) {
            console.error(error);
            toast.error("Can't delete active Location With Members.");
            return "err";
        }
    }

    useEffect(() => {
        getScheduledNotifications();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // Memoized value of the authentication context
    const contextValue = useMemo(
        () => ({
            loading,
            scheduledNotifications,
            addScheduledNotification,
            deleteScheduledNotification,
        }),
        [logged, loading, scheduledNotifications] // eslint-disable-line react-hooks/exhaustive-deps
    );

    // Provide the authentication context to the children components
    return (
        <ScheduledNotificationContext.Provider value={contextValue}>{children}</ScheduledNotificationContext.Provider>
    );
};

export const useScheduledNotifications = () => {
    return useContext(ScheduledNotificationContext);
};

export default ScheduledNotificationProvider;

