import {
    Button,
    Container,
    FormControl,
    FormLabel,
    HStack,
    Input,
    Switch,
    Text,
    Textarea,
    VStack
} from '@chakra-ui/react';
import React from 'react';
import { useFormik } from "formik";
import { Link } from 'react-router-dom';

export default function AddSubscriptionPlan() {
    const formik = useFormik({
        initialValues: {
            s_plan_name: "",
            original_price: "",
            des_price: "",
            features: "",
        },
        onSubmit: async (values) => {
            console.log(values);
        }
    });

    return (
        <Container maxWidth='9xl'>
            <Text className='custom' fontSize={{ base: 'xs', md: "lg" }} fontWeight="400">
                <Link to={'/subscription-plan'}>
                Subscription Management
                </Link>
                <Text as="span" color="black" pl={2} fontWeight={600}>
                    &gt;{' '} Add Subscription
                </Text>
            </Text>

            <form onSubmit={formik.handleSubmit}>
                <VStack w={{ base: '100%', md: '70%' }} alignItems={'start'} mt={8} spacing={4}>
                    <FormControl >
                        <FormLabel fontSize={'sm'}>Subscription Plan Name</FormLabel>
                        <Input
                            id="s_plan_name"
                            name="s_plan_name"
                            type="text"
                            variant="filled"
                            fontSize="sm"
                            py={4}
                            px={10}
                            bg="none"
                            borderColor="gray.100"
                            _focus={{
                                borderColor: "primary.800",
                            }}
                            borderRadius="10"
                            value={formik.values.s_plan_name}
                        />
                    </FormControl>

                    <FormControl >
                        <FormLabel fontSize={'sm'}>Original Price</FormLabel>
                        <Input
                            id="original_price"
                            name="original_price"
                            type="text"
                            variant="filled"
                            fontSize="sm"
                            py={4}
                            px={10}
                            bg="none"
                            borderColor="gray.100"
                            _focus={{
                                borderColor: "primary.800",
                            }}
                            borderRadius="10"
                            value={formik.values.original_price}
                        />
                    </FormControl>

                    <FormControl >
                        <FormLabel fontSize={'sm'}>Discounted Price</FormLabel>
                        <Input
                            id="des_price"
                            name="des_price"
                            type="text"
                            variant="filled"
                            fontSize="sm"
                            py={4}
                            px={10}
                            bg="none"
                            borderColor="gray.100"
                            _focus={{
                                borderColor: "primary.800",
                            }}
                            borderRadius="10"
                            value={formik.values.des_price}
                        />
                    </FormControl>

                    <FormControl >
                        <FormLabel fontSize={'sm'}>Features</FormLabel>
                        <Textarea
                            id="features"
                            name="features"
                            type="text"
                            variant="filled"
                            fontSize="sm"
                            py={4}
                            px={10}
                            bg="none"
                            borderColor="gray.100"
                            _focus={{
                                borderColor: "primary.800",
                            }}
                            borderRadius="10"
                            value={formik.values.features}
                        />
                    </FormControl>

                    <HStack mt={2}>
                        <Switch colorScheme='blue' size={'lg'} id='isChecked' isChecked />
                        <Text fontSize={'sm'} color={'green'}>Active</Text>
                    </HStack>

                    <Container w={'100%'} justifyItems={'center'}>
                        <HStack mx={{ md: '44px' }}>
                            <Button
                                my={4}
                                type="submit"
                                bg="primary.700"
                                py='6'
                                width="full"
                                borderRadius={50}
                                fontSize={'sm'}
                                color={'white'}
                            >
                                Submit
                            </Button>

                            <Button
                                my={4}
                                type="submit"
                                border={'2px'}
                                borderColor="primary.800"
                                color="primary.700"
                                py='6'
                                width="full"
                                borderRadius={50}
                                fontSize={'sm'}
                                bg={'none'}
                            >
                                Reset
                            </Button>
                        </HStack>
                    </Container>
                </VStack>
            </form>
        </Container>
    )
}

