import React, { useState, useRef } from 'react';
import {
    Container,
    Text,
    SimpleGrid,
    VStack,
    Button,
    Stack,
    FormControl,
    Input,
    InputGroup,
    InputLeftElement,
    Textarea,
    Divider,
    Image,
    Box
} from '@chakra-ui/react';
import { useFormik } from "formik";
import { FaRegUserCircle } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { BiPhoneCall } from "react-icons/bi";
import { BiSolidLock } from "react-icons/bi";
import { Link } from 'react-router-dom';
import { useAdminMembers } from '../../providers/AdminProvider';
import { GoPencil } from "react-icons/go";
import { CiImageOn } from "react-icons/ci";
import { useAuth } from '../../providers/AuthProvider';
import { toast } from 'react-toastify';

export default function YourProfileDetails() {

    const { updateAdminMember, updateAdminPassword } = useAdminMembers();
    const { user, logout, updateLocaluser } = useAuth();

    const [profileImageSrc1, setProfileCatImageSrc1] = useState(null);
    const [profileImageSrc, setProfileCatImageSrc] = useState(null);
    const fileInputRef = useRef(null);

    const formik = useFormik({
        initialValues: {
            f_name: user.first_name || "",
            l_name: user.last_name || "",
            email: user.email || "",
            phone: user.phone_number || "",
            bio: user.bio || "",
            new_password: "",
            re_enter_new_password: "",
        },
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(false);
        }
    });

    const handleProfileSubmit = async () => {
        let phone = formik.values.phone;
        console.log(phone)

        if (formik.values.f_name == "" || formik.values.l_name == "") {
            return toast.error("First name or Last name is required!");
        }

        if (phone !== "" && phone.toString().length !== 10) {
            return toast.error("Phone number must be 10 digits!");
        }

        try {
            let res = await updateAdminMember(user._id, formik.values.f_name, formik.values.l_name, formik.values.bio, phone, profileImageSrc);
            console.log(res.data);
            updateLocaluser(res.data);
            // toast.success("Profile updated successfully!");
        } catch (error) {
            toast.error("Failed to update profile.");
        }
    };

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();

        if (formik.values.new_password !== formik.values.re_enter_new_password) {
            return toast.error("Passwords do not match!");
        }

        if (formik.values.new_password == "" || formik.values.re_enter_new_password == "") {
            return toast.error("Please enter valid password!");
        }

        try {
            await updateAdminPassword(user._id, formik.values.new_password);
            toast.success("Password updated successfully!");
            formik.values.new_password = "";
            formik.values.re_enter_new_password = "";
        } catch (error) {
            toast.error("Failed to update password.");
        }
    };

    const handleIconClick = () => {
        fileInputRef.current.click();
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setProfileCatImageSrc1(reader.result);
            };
            reader.readAsDataURL(file);
        }
        setProfileCatImageSrc(event.target.files[0]);
    }

    return (
        <Container maxWidth='9xl' px={6}>
            <Text className='custom' fontSize="lg" fontWeight="500">
                <Link to='/setting'>
                    Settings &gt;{' '}
                </Link>
                <Text as="span" color="black" pl={2} fontWeight={700}>
                    Your Profile
                </Text>
            </Text>

            <Stack direction={{ base: 'column', md: 'row' }} mt={8} w={'100%'} spacing={12}>
                <VStack spacing={0} ml={{ md: 16 }}>
                    <Box position="relative" w={180} h={180} borderRadius={'50%'} display={'flex'} alignItems={'center'} justifyContent={'center'} border={'1px'} borderColor={'#3654EA'}>
                        {profileImageSrc1 ? (
                            <Image src={profileImageSrc1} w="100%" h="100%" objectFit="cover" borderRadius="50%" />
                        ) : user.profile_picture ?
                            (<Image src={user.profile_picture} w="100%" h="100%" objectFit="cover" borderRadius="50%" />)
                            : (
                                <CiImageOn color='grey' size={45} />
                            )}
                        <Box
                            position="absolute"
                            bottom={1}
                            right={2}
                            bg={"primary.800"}
                            w={12}
                            h={12}
                            borderRadius={'50%'}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            color={'white'}
                            onClick={handleIconClick}
                            cursor="pointer"
                        >
                            <GoPencil size={22} />
                        </Box>
                        <input
                            type="file"
                            name="img"
                            accept="image/png, image/jpg, image/jpeg"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                    </Box>
                    <Text className='custom' fontSize="xl" fontWeight="800" color="primary.700">
                        {user.first_name} {user.last_name}
                    </Text>
                    <Text fontSize="sm">{user.email}</Text>
                </VStack>

                <form onSubmit={formik.handleSubmit}>
                    <VStack w={'100%'} alignItems={'start'} ml={{ md: 12 }}>
                        <Text pb={1} fontWeight={'500'}>Your Details</Text>
                        <SimpleGrid w={'100%'} columns={{ base: 1, lg: 2 }} spacing={2}>
                            <FormControl>
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents="none"
                                        children={<FaRegUserCircle color="#3654EA" size={'18px'} />}
                                        mt={2}
                                    />
                                    <Input
                                        id="f_name"
                                        name="f_name"
                                        type="text"
                                        variant="filled"
                                        required={true}
                                        placeholder="First Name"
                                        fontSize="md"
                                        py={6}
                                        px={10}
                                        bg="none"
                                        borderColor="gray.100"
                                        _focus={{
                                            borderColor: "primary.700",
                                        }}
                                        borderRadius="10"
                                        value={formik.values.f_name}
                                        onChange={formik.handleChange}
                                    />
                                </InputGroup>
                            </FormControl>
                            <FormControl>
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents="none"
                                        children={<FaRegUserCircle color="#3654EA" size={'18px'} />}
                                        mt={2}
                                    />
                                    <Input
                                        id="l_name"
                                        name="l_name"
                                        type="text"
                                        variant="filled"
                                        required={true}
                                        placeholder="Last Name"
                                        fontSize="md"
                                        py={6}
                                        px={10}
                                        bg="none"
                                        borderColor="gray.100"
                                        _focus={{
                                            borderColor: "primary.700",
                                        }}
                                        borderRadius="10"
                                        value={formik.values.l_name}
                                        onChange={formik.handleChange}
                                    />
                                </InputGroup>
                            </FormControl>
                            <FormControl>
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents="none"
                                        children={<IoIosMail color="#3654EA" size={'18px'} />}
                                        mt={2}
                                    />
                                    <Input
                                        id="email"
                                        name="email"
                                        type="email"
                                        variant="filled"
                                        required={true}
                                        placeholder="Email ID"
                                        fontSize="md"
                                        py={6}
                                        px={10}
                                        bg="none"
                                        borderColor="gray.100"
                                        _focus={{
                                            borderColor: "primary.700",
                                        }}
                                        borderRadius="10"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                    />
                                </InputGroup>
                            </FormControl>
                            <FormControl>
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents="none"
                                        children={<BiPhoneCall color="#3654EA" size={'18px'} />}
                                        mt={2}
                                    />
                                    <Input
                                        id="phone"
                                        name="phone"
                                        type="number"
                                        variant="filled"
                                        placeholder="Phone Number"
                                        fontSize="md"
                                        py={6}
                                        px={10}
                                        bg="none"
                                        borderColor="gray.100"
                                        _focus={{
                                            borderColor: "primary.700",
                                        }}
                                        borderRadius="10"
                                        value={formik.values.phone}
                                        onChange={formik.handleChange}
                                    />
                                </InputGroup>
                            </FormControl>
                        </SimpleGrid>

                        <FormControl mt={2}>
                            <InputGroup>
                                <Textarea
                                    id="bio"
                                    name="bio"
                                    type="text"
                                    variant="filled"
                                    placeholder="Your Bio"
                                    fontSize="md"
                                    py={4}
                                    px={4}
                                    bg="none"
                                    borderColor="gray.100"
                                    _focus={{
                                        borderColor: "primary.700",
                                    }}
                                    borderRadius="10"
                                    value={formik.values.bio}
                                    onChange={formik.handleChange}
                                />
                            </InputGroup>
                        </FormControl>
                        <Button
                            fontSize={'14px'}
                            fontWeight={'700'}
                            colorScheme='primary'
                            borderRadius={'full'}
                            w={{base:'full', md:'auto'}}
                            px={10}
                            py={6}
                            onClick={handleProfileSubmit}
                            mt={6}
                        >
                            Save Your Profile
                        </Button>

                        <Divider my={8} />

                        <Text pb={1} fontWeight={'500'}>Update Password</Text>

                        <FormControl>
                            <InputGroup>
                                <InputLeftElement
                                    pointerEvents="none"
                                    children={<BiSolidLock color="#3654EA" size={'18px'} />}
                                    mt={2}
                                />
                                <Input
                                    id="new_password"
                                    name="new_password"
                                    type="password"
                                    variant="filled"
                                    placeholder="New Password"
                                    fontSize="md"
                                    py={6}
                                    px={10}
                                    bg="none"
                                    borderColor="gray.100"
                                    _focus={{
                                        borderColor: "primary.700",
                                    }}
                                    borderRadius="10"
                                    value={formik.values.new_password}
                                    onChange={formik.handleChange}
                                />
                            </InputGroup>
                        </FormControl>
                        <FormControl>
                            <InputGroup>
                                <InputLeftElement
                                    pointerEvents="none"
                                    children={<BiSolidLock color="#3654EA" size={'18px'} />}
                                    mt={2}
                                />
                                <Input
                                    id="re_enter_new_password"
                                    name="re_enter_new_password"
                                    type="password"
                                    variant="filled"
                                    placeholder="Re-enter New Password"
                                    fontSize="md"
                                    py={6}
                                    px={10}
                                    bg="none"
                                    borderColor="gray.100"
                                    _focus={{
                                        borderColor: "primary.700",
                                    }}
                                    borderRadius="10"
                                    value={formik.values.re_enter_new_password}
                                    onChange={formik.handleChange}
                                />
                            </InputGroup>
                        </FormControl>
                        <Button
                            fontSize={'14px'}
                            fontWeight={'700'}
                            colorScheme='primary'
                            borderRadius={'full'}
                            w={{base:'full', md:'auto'}}
                            px={10}
                            py={6}
                            onClick={handlePasswordSubmit}
                            mt={6}
                        >
                            Update Password
                        </Button>
                    </VStack>
                </form>
            </Stack>
        </Container>
    );
}
