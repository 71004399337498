import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Box, HStack, VStack, useBreakpointValue } from '@chakra-ui/react';
import Navbar from '../common/Navbar';
import SidebarLinks from '../common/SidebarLinks';
import AdminProvider from '../../providers/AdminProvider';
import { useAuth } from '../../providers/AuthProvider';
import FaqProvider from '../../providers/FaqProvider';
import UserProvider from '../../providers/UserProvider';
import SupportTicketProvider from '../../providers/SupportTicketProvider';
import NotificationGroupProvider from '../../providers/NotificationGroupProvider';
import UsersAndMatchedProvider from '../../providers/UsersAndMatchedProvider';
import ScheduledNotificationProvider from '../../providers/ScheduledNotificationProvider';
import NotificationProvider from '../../providers/NotificationProvider';
import SupportTicketChat from '../../providers/SupportTicketChatProvider';

export default function PrivateRoutes() {
    const { loading, logged } = useAuth();
    const sideBarVariant = useBreakpointValue({ base: 'drawer', md: 'sidebar' });

    if (loading) {
        return (
            <React.Fragment>
                <p>Loading...</p>
            </React.Fragment>
        )
    }

    return (
        logged ?
            <AdminProvider>
                <UserProvider>
                    <FaqProvider>
                        <SupportTicketProvider>
                            <SupportTicketChat>
                                <NotificationGroupProvider>
                                    <UsersAndMatchedProvider>
                                        <ScheduledNotificationProvider>
                                            <NotificationProvider>
                                                <HStack spacing='0'>
                                                    {sideBarVariant === 'sidebar' && <SidebarLinks />}
                                                    <Box h='100vh' w='100%' overflowY='auto'>
                                                        <VStack>
                                                            <Navbar />
                                                            <Outlet />
                                                        </VStack>
                                                    </Box>
                                                </HStack>
                                            </NotificationProvider>
                                        </ScheduledNotificationProvider>
                                    </UsersAndMatchedProvider>
                                </NotificationGroupProvider>
                            </SupportTicketChat>
                        </SupportTicketProvider>
                    </FaqProvider>
                </UserProvider>
            </AdminProvider>
            : <Navigate to='/login' />
    )
}


