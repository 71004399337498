import axios from "axios";
import { createContext, useContext, useMemo, useState } from "react";
import { url } from "../config";
import { toast } from "react-toastify";
import { useAuth } from "./AuthProvider";

const SupportTicketChatContext = createContext();

const SupportTicketChat = ({ children }) => {
    const [ticketChats, setTicketChats] = useState([]);
    const [loading, setLoading] = useState(true);

    const { token, logged, logout } = useAuth();

    async function addTicketChat(ticket, message ) {
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            var res = await axios.post(`${url}/admin/support-chat/messages/from-admin`,
                {
                    ticket,
                    message
                }, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            }
            );
            await getTicketChats(ticket);
            toast.success("Reply sent successfully!");
            return res.data;
        } catch (error) {
            console.error(error);
            if (error.response.status === 400) {
                toast.error(error.response.data.error);
            }
            if (error.response.status === 401) {
                toast.error("Session Expired");
                logout();
            }
            throw error;
        }
    }

    async function changeStatusOfTicket(ticket, status) {
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            var res = await axios.put(`${url}/admin/ticket-status`,
                {
                    ticket_id: ticket,
                    status
                }, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            });
            toast.success("Updated Status !");
            return res.data;
        } catch (error) {
            if (error.response.status === 400) {
                toast.error(error.response.data.error);
            }
            if (error.response.status === 401) {
                toast.error("Session Expired");
                logout();
            }
            throw error;
        }
    }

    async function getTicketChats(ticket) {
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            var res = await axios.post(`${url}/admin/support-chat/messages`,
                {
                    ticket
                }, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            });
            setTicketChats(res.data.data);
    
        } catch (error) {
            console.error("GET Support Ticket Chat ERROR");
            console.error(error);
            if (error.response.status === 401) {
                console.error("NOT AUTHENTICATED");
            }
            return "err";
        }
    }
   
    const contextValue = useMemo(
        () => ({
            loading,
            ticketChats,
            addTicketChat,
            getTicketChats,
            changeStatusOfTicket
        }),
        [logged, loading, ticketChats, addTicketChat, getTicketChats, changeStatusOfTicket] // eslint-disable-line react-hooks/exhaustive-deps
    );

    // Provide the authentication context to the children components
    return (
        <SupportTicketChatContext.Provider value={contextValue}>{children}</SupportTicketChatContext.Provider>
    );
};

export const useSupportTicketChat = () => {
    return useContext(SupportTicketChatContext);
};

export default SupportTicketChat;