import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Text,
    Button,
} from '@chakra-ui/react'

export const ConfirmDialog = ({ isOpen, onClose, onConfirm, title, body }) => {
    return (
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <Text>
                        {body}
                    </Text>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onConfirm} colorScheme='red' mr={3}>
                        YES
                    </Button>
                    <Button colorScheme='whatsapp' onClick={onClose}>NO</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};