import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { url } from "../config";
import { toast } from "react-toastify";
import { useAuth } from "./AuthProvider";

const FaqsContext = createContext();

const FaqProvider = ({ children }) => {
    const [faqs, setFaqs] = useState([]);
    const [loading, setLoading] = useState(true);

    const { token, logged , logout} = useAuth();

    async function addFaq(title, content) {
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            var res = await axios.post(`${url}/admin/faqs`,
                {
                    title,
                    content
                }, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            }
            );
            await getFaqs();
            toast.success("Added !");
            return res.data;
        } catch (error) {
            console.error(error);
            if (error.response.status === 400) {
                toast.error(error.response.data.error);
            }
            if (error.response.status === 401) {
                toast.error("Session Expired");
                logout();
            }
            throw error;
        }
    }

    async function editFaq(id, title, content) {
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            const res = await axios.put(`${url}/admin/faqs/${id}`, {
                title,
                content
            }, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            });
            await getFaqs();
            toast.success("Updated !");
            return res.data;
        } catch (error) {
            console.error(error);
            if (error.response.status === 400) {
                toast.error(error.response.data.error);
            }
            throw error;
        }
    }

    async function getFaqs() {
        console.log("Get Faqs");
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            var { data } = await axios.get(`${url}/admin/faqs`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
            });
            setFaqs(data.data);

        } catch (error) {
            console.error("GET FAQ ERROR");
            console.error(error);
            if (error.response.status === 401) {
                console.error("NOT AUTHENTICATED");
                // logout();
            }
            return "err";
        }
    }

    async function deleteFaq(id) {
        console.log(id)
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            await axios.delete(`${url}/admin/faqs/${id}`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            }
            );
            await getFaqs();
            toast.success("DELETED Successfully !");
        } catch (error) {
            console.error(error);
            toast.error("Can't delete active Location With Members.");
            return "err";
        }
    }

    useEffect(() => {
        getFaqs();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // Memoized value of the authentication context
    const contextValue = useMemo(
        () => ({
            loading,
            faqs,
            addFaq,
            editFaq,
            deleteFaq,
        }),
        [logged, loading, faqs] // eslint-disable-line react-hooks/exhaustive-deps
    );

    // Provide the authentication context to the children components
    return (
        <FaqsContext.Provider value={contextValue}>{children}</FaqsContext.Provider>
    );
};

export const useFaqs = () => {
    return useContext(FaqsContext);
};

export default FaqProvider;
