import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { url } from "../config";
import { useAuth } from "./AuthProvider";

const UsersAndMatchedContext = createContext();

const UsersAndMatchedProvider = ({ children }) => {
    const [usersMatched, setUsersMatched] = useState([]);
    const [loading, setLoading] = useState(true);

    const { token, logged } = useAuth(); 

    async function getUsersAndMatched() {
        console.log("Get Users and their matched");
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            var { data } = await axios.get(`${url}/admin/users-and-matches`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
            });
            console.log(data);
            setLoading(false);
            setUsersMatched(data);

        } catch (error) {
            console.error(error);
            if (error.response.status === 401) {
                console.error("NOT AUTHENTICATED");
                // logout();
            }
            return "err";
        }
    }

    useEffect(() => {
        getUsersAndMatched();
    }, []) 

    // Memoized value of the authentication context
    const contextValue = useMemo(
        () => ({
            loading,
            logged,
            usersMatched,
        }),
        [logged, loading, usersMatched] 
    );

    // Provide the authentication context to the children components
    return (
        <UsersAndMatchedContext.Provider value={contextValue}>{children}</UsersAndMatchedContext.Provider>
    );
};

export const useUsersAndMatched = () => {
    return useContext(UsersAndMatchedContext);
};

export default UsersAndMatchedProvider;
