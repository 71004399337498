import React from "react"
import { Button, Drawer, DrawerContent, DrawerOverlay, useDisclosure } from "@chakra-ui/react"
import SidebarLinks from "./SidebarLinks"
import { TbBaselineDensitySmall } from "react-icons/tb"

export default function SideBar() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = React.useRef();

    return (
        <>
            <Button ref={btnRef} colorScheme='gray' onClick={onOpen}>
                <TbBaselineDensitySmall />
            </Button>
            <Drawer
                isOpen={isOpen}
                placement='left'
                onClose={onClose}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent bg='transparent' shadow='0'>
                    <SidebarLinks onClick={onClose} />
                </DrawerContent>
            </Drawer>
        </>
    )
}