import {
    Card,
    Container,
    HStack,
    Text,
    SimpleGrid,
    VStack,
    Image,
    Stack
} from '@chakra-ui/react';
import React from 'react';
import { FaCircle } from "react-icons/fa";
import { Link } from 'react-router-dom';
import ReportsAndAnalytics from './ReportsAndAnalytics';

export default function Dashboard() {

    return (
        <>
            <Container maxWidth='9xl'>

                <SimpleGrid
                    columns={{ base: 1, md: 2, lg: 3 }}
                    spacing={4}
                >
                    <Link to={'/user-management'}>
                        <Card mt='5' boxShadow={'lg'}>
                            <HStack width='100%' justifyContent='space-between' p='5'>
                                <VStack w={'100%'} alignItems={'center'}>
                                    <Image src='/user_management.png' h={100} />
                                    <Text fontWeight={600} className='custom' fontSize='xl' color='primary.900'>User Management</Text>
                                </VStack>
                            </HStack>
                        </Card>
                    </Link>

                    <Link to={'/subscription-plan'}>
                        <Card mt='5' boxShadow={'lg'}>
                            <HStack width='100%' justifyContent='space-between' p='5'>
                                <VStack w={'100%'} alignItems={'center'}>
                                    <Image src='/subscription_plan.png' h={100} />
                                    <Text fontWeight={600} className='custom' fontSize='xl' color='primary.900'>Subscription Plan</Text>
                                </VStack>
                            </HStack>
                        </Card>
                    </Link>
                    <Link to={'/content-management'}>
                        <Card mt='5' boxShadow={'lg'}>
                            <HStack width='100%' justifyContent='space-between' p='5'>
                                <VStack w={'100%'} alignItems={'center'}>
                                    <Image src='/content_management.png' h={100} />
                                    <Text fontWeight={600} className='custom' fontSize='xl' color='primary.900'>Content Management</Text>
                                </VStack>
                            </HStack>
                        </Card>
                    </Link>

                </SimpleGrid>

                <ReportsAndAnalytics />

                <Text fontSize={'xs'} mt={6} textAlign={{lg:'end'}}>
                    Copyright © 2024 Forrest All rights reserved.
                </Text>
            </Container>
        </>
    )
}
