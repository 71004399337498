import React, { useState, useRef } from 'react';
import {
  Box,
  Button,
  Input,
  VStack,
  Text,
  HStack,
  Link,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../../providers/AuthProvider';

export default function OTPVerification() {
  const { login, loginOtp } = useAuth();
  const { state } = useLocation();

  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputSize = useBreakpointValue({ base: "md", md: "lg" });
  const inputRefs = useRef([]);
  const navigate = useNavigate();

  const handleChange = (index, value) => {
    if (value.length > 1) {
      value = value.slice(0, 1); // Ensure only one character is allowed
    }

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move to the next input field if not the last one
    if (value && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleSubmit = async (values) => {
    const otpValue = otp.join('');
    try {
      await login(state.email, otpValue);
      navigate('/')
    } catch (error) {
      console.error(error);
      toast.error("Invalid OTP");
    }
  };

  async function resendOtp() {
    try {
      await loginOtp(state.email, state.password);
    } catch (error) {
      toast.error("Error Sending OTP");
    }
  }

  return (
    <Box
      w="100vw"
      h="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgImage="url('./login.jpg')"
      bgSize="cover"
      bgPosition="center"
      bgAttachment="fixed"
      overflow="hidden" 
    >
      <Box
        bg="rgba(255, 255, 255, 0.3)"
        p={8}
        boxShadow="lg"
        border={'1px'}
        borderRadius={'xl'}
        borderColor={'#FFFFFF'}
        w={{ base: '90%', md: '400px' }}
        textAlign="center"
        position="relative" 
        overflow="hidden" 
      >
        <VStack spacing={4} align="center" mb={8} mt={4}>
        <Box
              bgImage="url('./rainbow.png')"
              bgSize="contain"
              bgPosition="center"
              bgRepeat="no-repeat"
              width="75px"
              height="75px"
              position="absolute"
              top="10%"
              left="calc(50% + 100px)"
              transform="translateY(-50%)"
              zIndex={1}
            />
          <Box
            position="relative"
            width="full"
            height="auto"
            textAlign="center"
            overflow="hidden"
            mt={6}
          >
            <Box
              bgImage="url('./auth_bg.png')"
              bgSize="contain"
              bgPosition="center"
              bgRepeat="no-repeat"
              width="190px"
              height="60px"
              position="absolute"
              top="60%"
              left="50%"
              transform="translate(-50%, -50%)"
              zIndex={1}
            />
            <Text
              fontSize="2xl"
              fontWeight="bold"
              position="relative"
              color="black"
              display="inline-block"
              zIndex={1}
              top="45%"
            >
              Verify Your Email Address
            </Text>
          </Box>
          <Text fontSize="md">
            Code is sent to
            <Text as="span" pl={1} fontWeight={700}>
              {state.email}
            </Text>
          </Text>
        </VStack>
        <VStack spacing={4} mb={4}>
          <HStack spacing={4} justify="center">
            {otp.map((value, index) => (
              <Input
                key={index}
                ref={el => inputRefs.current[index] = el}
                size={inputSize}
                maxLength={1}
                textAlign="center"
                fontSize="2xl"
                p={4}
                w="50px"
                h="50px"
                borderRadius="xl"
                border="1px"
                borderColor="gray.400"
                bg="white"
                value={value}
                onChange={(e) => handleChange(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(index, e)}
              />
            ))}
          </HStack>
          <Text fontSize="sm" my={6}>
            Didn’t receive any code? <br />
            <Link onClick={resendOtp} fontWeight="700">Request new code again.</Link>
          </Text>
        </VStack>
        <Button
          onClick={handleSubmit}
          bg="primary.900"
          color="#FFFFFF"
          w="100%"
          p={6}
          borderRadius="18px 0px 18px 18px"
          fontSize="sm"
          fontWeight="400"
        >
          Verify & Login
        </Button>
      </Box>
    </Box>
  );
}
